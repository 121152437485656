angular.module('uti.skayo.onlinesale').service('Http500Interceptor', function($injector,SweetAlert, $q) {
    var service = this;

    service.request = function(config) {
        return config;
    };

    service.responseError = function(response) {
        var gettextCatalog = $injector.get('gettextCatalog');

        if (response.status === 500) {
            if (response.data && response.data.ExceptionMessage && response.data.ExceptionType == "Uti.Skayo.OnlineSale.DomainModel.BusinessException")
            {
                //display "business exception"
                var message = response.data.ExceptionMessage;

                //in case of multiple messages
                messages = message.split(';');
                messageHtml = '';

                for (var i=0;i<messages.length;i++)
                    messageHtml = messageHtml+gettextCatalog.getString(messages[i])+"<br/>";

                SweetAlert.swal({
                    title:gettextCatalog.getString("Warning"),
                    text: messageHtml ,
                    type: "error",
                    html: true});
            }
            else if (response.data.ExceptionMessage.includes("already has a pending recharge")) {
                SweetAlert.swal({
                    title: gettextCatalog.getString("System error"),
                    text: "Există deja o reîncărcare în așteptare pentru această aplicație!",
                    type: "error",
                    html: true
                });
            }
            else {

                SweetAlert.swal({
                    title: gettextCatalog.getString("System error"),
                    text: gettextCatalog.getString("Sorry, a technical error occured."),
                    type: "error",
                    html: true
                });
            }
        }
        return $q.reject(response);
    };

})