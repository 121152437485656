var app = angular.module('uti.skayo.onlinesale', ['uti.skayo.onlinesale.views', 'ngRoute', 'gettext', 'ngCookies', 'ui.bootstrap', 'blockUI', 'ngFlag', 'ngMessages', 'oitozero.ngSweetAlert', 'ngCookies']);

app.run(function (gettextCatalog, blockUIConfig, gettext, SkayoLocaleService) {

    var lang = localStorage.getItem("skayo-client-language");
    if (!lang) {
        lang = "ro";
        localStorage.setItem("skayo-client-language" , lang);
    }

    gettextCatalog.setCurrentLanguage(lang); //should be 'ro' for
    SkayoLocaleService.setLocale(lang);
    //gettextCatalog.debug = true;
    blockUIConfig.message = gettextCatalog.getString("Please wait");
});

app.config(function ($routeProvider, $httpProvider, $logProvider) {

    $logProvider.debugEnabled(true);
    $httpProvider.interceptors.push('Http401Interceptor');
    $httpProvider.interceptors.push('Http500Interceptor');

    var lang = null;
    try {
        lang = localStorage.getItem("skayo-client-language");
    }
    catch (exception) { }
    lang = lang || "ro";
    var faqTemplate = '/app/views/faq_' + lang + ".html";

    //$locationProvider.html5Mode(true);
    $routeProvider
        .when("/login",
        {
            templateUrl: "/app/views/login.html",
            controller: "LoginController"
        })
        .when("/home",
        {
            templateUrl: "/app/views/home.html",
            controller: "HomeController"
        })
      
        .when("/myCards",
        {
            templateUrl: "/app/views/myCards.html",
            controller: "MyCardsController"
        })
        .when("/transactionHistory",
        {
            templateUrl: "/app/views/transactionHistory.html",
            controller: "TransactionHistoryController"
        })
        .when("/products",
        {
            templateUrl: "/app/views/products.html",
            controller: "ProductsController"
        })
        .when("/myAccount",
        {
            templateUrl: "/app/views/myAccount.html",
            controller: "MyAccountController"
        })

        .when("/register", {
            templateUrl: "/app/views/register.html",
            controller: "RegisterController"
        })

        .when("/registerConfirm/:code", {
            templateUrl: "/app/views/registerConfirm.html",
            controller: "RegisterConfirmController"
        })

        .when("/recoverPassword", {
            templateUrl: "/app/views/recoverPassword.html",
            controller: "RecoverPasswordController"
        })

        .when("/recoverPasswordConfirm/:code", {
            templateUrl: "/app/views/recoverPasswordConfirm.html",
            controller: "RecoverPasswordConfirmController"
        })

        .when("/error", {
            templateUrl: "/app/views/error.html",
            controller: "ErrorController"
        })

        .when("/help", {
            templateUrl: "/app/views/help.html",
            controller: "HelpController"
        })
        
        //static stuff
        .when("/infoTransport",
        {
            templateUrl: "/app/views/infoTransport.html",
            controller:"InfoTransportController"
               
        })
        .when("/infoTransportHome",
            {
                templateUrl: "/app/views/infoTransportHome.html",
                controller: "InfoTransportHomeController"

            })
        .when("/contact", { templateUrl: "/app/views/contact.html" })
        .when("/faq",
        {
            templateUrl: faqTemplate,
            controller: "FaqController"
        })
        .when("/faq_en_US", { templateUrl: "/app/views/faq_en_US.html" })
        .when("/faq_bg", { templateUrl: "/app/views/faq_bg.html" })
        .when("/faq_ru", { templateUrl: "/app/views/faq_ru.html" })
        .when("/faq_ro", { templateUrl: "/app/views/faq_ro.html", controller: "FaqController" })
        .when("/consumerProtection", { templateUrl: "/app/views/consumerProtection.html" })

        .when("/transactions", {
            templateUrl: "/app/views/admin/transactions.html",
            controller: "TransactionsController"
        })

        //administration
        .when('/adminSearch', {
            templateUrl: "/app/views/adminSearch.html",
            controller: "AdminSearchController"
        })

        .when('/adminTransactions', {
            templateUrl: "/app/views/adminTransactions.html",
            controller: "AdminTransactionsController"
        })

        .when('/adminAuditLog', {
            templateUrl: "/app/views/adminAuditLog.html",
            controller: "AdminAuditController"
        })

        .when('/userAuditLog', {
            templateUrl: "/app/views/adminUserAuditLog.html",
            controller: "AdminUserAuditController"
        })

        .when('/adminSales', {
            templateUrl: "/app/views/adminSales.html",
            controller: "AdminSalesController"
        })

        .when('/adminSettings', {
            templateUrl: "/app/views/adminSettings.html",
            controller: "AdminSettingsController"
        })

        .when('/adminSettingCreate', {
            templateUrl: "/app/views/adminSettingCreate.html",
            controller: "AdminSettingCreateController"
        })
    .otherwise({ redirectTo: function () { return document.cookie.includes('login') ? '/home' : '/login'; } });

});